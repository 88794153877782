'use strict';

import Backbone from 'backbone';
import DateUtil from '../utils/dateutil';

export default Backbone.Model.extend({

  parse: function (response, options) {
    if (typeof response.completed_date === 'string') {
      response.completed_date = DateUtil.parseApiDateTime(response.completed_date);
    }
    return response;
  },

  defaults: {
    id: null,
    title: '',
    completed_date: null
  }
});
